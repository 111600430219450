<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.6rem;">名称：</span>
					<el-input v-model="searchinput" placeholder="请输入名称" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>						
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="110" @getdata="gettable" @changswitch="changswitch">
						<template slot="btns" slot-scope="{ scope }">
							<!-- <el-button @click="select(scope.row)" type="primary" size="mini">
								选择代参加人
							</el-button> -->
						</template>
					</MyTable>
				</div>
			</div>
		</div>	
		
		<detail ref="mydetail" :istz='true'></detail>
		
		
		
		<selectuser ref="myselectuser" @selectedry="selectedry"></selectuser>
	</div>
</template>

<script>	
import selectuser from '@/components/select_user.vue'
import detail from '../meeting_list/components/detail.vue'
	export default{		
		components:{ detail,selectuser },
		data() {
			return{
				showqj:false,
				qjliyou: '',
				user_id: '',
				user_name: '',
				qjid:'',
				searchinput:'',
				tableHeader: [{
						prop: 'id',
						name: '序',
						width: '50'
					},					
					{
						prop: 'name',
						name: '姓名',
						width: ''
					},
					{
						prop: 'wymeeting_name',
						name: '所属会议',
						width: ''
					},
					{
						prop: 'liyou',
						name: '请假原因',
						width: ''
					},
					{
						prop: 'cztime',
						name: '提交时间',
						width: ''
					},
					{
						prop: 'tjname',
						name: '推荐代参加人',
						width: ''
					},
					{
						prop: 'duser_name',
						name: '代参加人',
						width: ''
					}
				],
				tableData: [],
			}
		},
		mounted() {
			this.gettable()
		},
		methods:{
			select(row){
				this.qjid = row.id
				let cxidlist = this.user_id?this.user_id.split(','):[]
				let cxnamelist = this.user_name?this.user_name.split(','):[]
				let list = []
				if(cxidlist.length>0){
					cxidlist.forEach((item,index)=>{
						let obj={
							name:cxnamelist[index],
							id:Number(item)
						}
						list.push(obj)
					})
				}				
				this.$refs.myselectuser.isdanxuan = true
				this.$refs.myselectuser.selectuser = list.length>0?list:[]
				this.$refs.myselectuser.dialogVisible = true
			},
			selectedry(e){
				this.user_id = e.map((item)=>{ return item.id })
				this.user_id = this.user_id.join(',')
				this.user_name = e.map((item)=>{ return item.name })
				this.user_name = this.user_name.join(',')
				
				this.$post({
					url: '/api/Wymeetingtz/qddhr',
					params: {
						id:this.qjid,
						user_id:this.user_id
					}
				}).then((res) => {
					this.$message.success('操作成功')
					this.gettable()
				})
			},
			qingjia(row) {
				this.qjid = row.id
				this.showqj = true
			},
			postqingjia(){
				if(!this.qjliyou){
					this.$message.warning('请填写请假理由')
					return
				}
				this.$post({
					url: '/api/wymeetingtz/hybmqj',
					params: {
						id: this.qjid,
						type:4,
						liyou:this.qjliyou,
						duser_id:this.user_id
					}
				}).then((res) => {
					this.$message.success('操作成功')
					this.clearqj()
					this.gettable()
				})
			},
			clearqj(){
				this.showqj = false
				this.qjliyou=''
				this.user_id=''
				this.user_name=''
				this.qjid=''
			},
			qiandao(row){
				this.$confirm('请确认是否签到会议?', '提示', {
					confirmButtonText: '签到',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post({
						url: '/api/wymeetingtz/hyqd',
						params: {
							id: row.id,
							qdfs:1
						}
					}).then((res) => {
						this.$message.success('签到成功')
						this.gettable()
					})
				}).catch(() => {
					
				})
			},
			canhui(row) {
				this.$confirm('请确认是否参加会议?', '提示', {
					confirmButtonText: '参加',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post({
						url: '/api/wymeetingtz/hybmqj',
						params: {
							id: row.id,
							type:3
						}
					}).then((res) => {
						this.$message.success('参加成功')
						this.gettable()
					})
				}).catch(() => {
					
				})
			},
			showeidt(row){
				this.$post({
					url: '/api/wymeeting/details',
					params: {
						id:row.wymeeting_id,
						type:1
					}
				}).then((res) => {
					this.$refs.mydetail.form = res
					this.$refs.mydetail.isedite = false
					this.$refs.mydetail.dialogVisible = true		
				})
			},
			changswitch(row){
				this.$post({
					url: '/api/room/edit',
					params: row
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
				})
			},
			search(){
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable(){
				this.$post({
					url: '/api/wymeeting/qjgl',
					params: {
						name: this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize
					}
				}).then((res) => {
					this.tableData = res.list
					this.$refs.mytable.total = res.data.count
				})
			},
			addnew(){
				this.$router.push('/system/meeting_add')
			},
			showdate(row){
				this.$refs.myroomdate.dialogVisible = true
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import 'meeting_qj.scss';
</style>